import type { EventProduct } from "~/queries/useLandingPageEventQuery";

export const useEventOrderStore = defineStore("event-order", () => {
  const company = ref<string | null>(null);
  const cart = ref<EventProduct | null>(null);

  return {
    company,
    cart,
  };
});
