import { computed, Ref } from "vue";

type Currency = "CZK" | "EUR" | "USD";
type LocaleMap = {
  [key in Currency]: string;
};

const localeMap: LocaleMap = {
  CZK: "cs-CZ",
  EUR: "de-DE",
  USD: "en-US",
};

export const usePrice = (
  price: number,
  locale: Currency = "CZK",
  fractionDigits: number = 0,
): Ref<string> => {
  return computed(() => {
    const hasDecimal = price % 1 !== 0;
    const actualFractionDigits = hasDecimal ? fractionDigits : 0;

    const currencyLocale: string = localeMap[locale];

    const formatOptions: Intl.NumberFormatOptions = {
      style: "currency",
      currency: locale,
      minimumFractionDigits: actualFractionDigits,
      maximumFractionDigits: actualFractionDigits,
    };

    return new Intl.NumberFormat(currencyLocale, formatOptions).format(price);
  });
};
